import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import LightboxModal from "../../Modal/LightboxModal/LightboxModal";

export default {
    name: 'GalleryMain',
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        LightboxModal
    },
    props: {
        images: {
            type: Array,
        },
    },
    data() {
        return {
            lightbox: null
        }
    },
    mounted() {
        this.lightbox = this.$refs.lightbox;
    },
    methods: {
        openLightbox(index) {
            this.lightbox.setIndex(index);
            this.$store.dispatch('utils/openModal', 'lightbox');
        }
    }
}