<template>
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4290_25852)">
      <path
          d="M0 5.41071C0.0166693 2.47281 2.2653 0.202466 5.16518 0.0121283C7.95987 -0.171079 10.5143 1.79593 10.9391 4.45024C11.1563 5.80784 10.7707 7.0508 10.155 8.24713C9.05142 10.3919 7.47186 12.1982 5.72619 13.8849C5.57674 14.0291 5.44396 14.045 5.28761 13.8942C3.43502 12.1011 1.76062 10.1829 0.661598 7.86371C0.264984 7.02666 0.00919684 6.15396 0 5.41071ZM8.02137 5.25767C8.02022 3.92037 6.89074 2.84636 5.48994 2.8502C4.10639 2.85404 2.98265 3.93079 2.98208 5.25328C2.98208 6.58839 4.11156 7.66295 5.51351 7.66131C6.89418 7.65966 8.02252 6.57852 8.0208 5.25822L8.02137 5.25767Z"
          fill="#FF7A00"/>
    </g>
    <defs>
      <clipPath id="clip0_4290_25852">
        <rect width="11" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
