import MapPinIcon from "@/components/Common/Icons/MapPinIcon";
import WorkingHoursIcon from "@/components/Common/Icons/WorkingHoursIcon";

export default {
    name: 'CleaningLocationHoursInfo',
    props: {},
    components: {
        MapPinIcon,
        WorkingHoursIcon
    },
    mounted() {
    }
}
