<template>
  <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.886719 35.4829L42.8945 35.4829" stroke="#595F8A" stroke-width="0.910777" stroke-linecap="round"/>
    <g clip-path="url(#clip0_4317_11389)">
      <path
          d="M6.93977 13.9171C6.93977 13.4464 7.18213 13.0088 7.58117 12.7591L20.5332 4.65321C20.9792 4.37412 21.5458 4.37589 21.99 4.65777L35.3064 13.1081C35.7013 13.3587 35.9406 13.7939 35.9406 14.2616V34.061C35.9406 34.8155 35.329 35.4272 34.5744 35.4272H8.30599C7.55148 35.4272 6.93983 34.8155 6.93983 34.061L6.93977 13.9171Z"
          fill="#F7F7F7" stroke="#595F8A" stroke-width="0.910777"/>
      <path
          d="M36.2486 15.7155C36.3394 15.776 36.4303 15.8366 36.5213 15.8972C36.9034 16.1518 37.2863 16.4069 37.6669 16.6639M36.2486 15.7155L4.66543 16.7425C3.75884 17.3287 2.7908 17.1467 2.24709 16.39C1.95787 15.9867 1.86466 15.5535 1.93468 15.1564C2.00472 14.7591 2.24506 14.3601 2.69276 14.0412L2.69306 14.041C8.50656 9.89249 14.334 5.76097 20.167 1.63432L20.1671 1.6342C20.5755 1.34499 20.9225 1.2319 21.2413 1.23576C21.562 1.23963 21.9161 1.36258 22.3363 1.66109L22.3367 1.66137C28.1197 5.76077 33.8992 9.86346 39.6717 13.9763L39.6718 13.9764C40.1536 14.3194 40.4193 14.7384 40.5044 15.1456C40.5888 15.5492 40.5043 15.9778 40.2186 16.3711L40.2185 16.3713C39.9132 16.7921 39.5203 17.0163 39.0994 17.0696C38.6735 17.1234 38.1758 17.007 37.6669 16.6639M36.2486 15.7155L36.1934 15.6761C31.4438 12.2862 26.6944 8.89656 21.9503 5.50182L21.9502 5.50176C21.8054 5.39825 21.6031 5.26498 21.3549 5.22809C21.0762 5.18666 20.8206 5.2746 20.5869 5.44301L20.8532 5.81245M36.2486 15.7155C36.2298 15.7029 36.2109 15.6904 36.1921 15.6778C36.188 15.6751 36.1839 15.6723 36.1798 15.6696L35.9285 16.0464L35.6084 15.7231L35.6067 15.7248L35.5527 15.7783C30.9285 12.4779 26.3044 9.17748 21.6853 5.87216C21.4062 5.67256 21.1822 5.57532 20.8532 5.81245M37.6669 16.6639L37.4121 17.0413M37.6669 16.6639C37.6668 16.6639 37.6668 16.6638 37.6667 16.6638L37.4121 17.0413M37.4121 17.0413C38.5784 17.8278 39.8394 17.6691 40.5871 16.6387L36.3139 16.3063C36.6809 16.5508 37.0475 16.7951 37.4121 17.0413ZM20.8532 5.81245C17.0524 8.53435 13.2461 11.2497 9.44048 13.9647C8.47324 14.6547 7.50604 15.3447 6.53898 16.0348L6.28561 15.6564C6.28373 15.6576 6.28185 15.6589 6.27997 15.6602M20.8532 5.81245L20.588 5.44221C16.7877 8.16378 12.982 10.8788 9.17646 13.5936C8.21092 14.2825 7.24539 14.9713 6.27997 15.6602M6.27997 15.6602C6.09079 15.7869 5.90486 15.9129 5.72022 16.0381C5.36843 16.2766 5.02134 16.512 4.66546 16.7425L6.27997 15.6602Z"
          fill="#F4564D" stroke="#595F8A" stroke-width="0.910777"/>
      <path
          d="M15.1289 35.7679C15.1392 29.7747 15.1668 23.7816 15.1427 17.7884C15.1392 16.9286 15.3942 16.6761 16.259 16.6863C19.627 16.7307 22.9966 16.7153 26.3663 16.6949C27.0037 16.6915 27.3069 16.8518 27.3017 17.5444C27.2793 21.1066 27.2914 24.6687 27.288 28.2308C27.288 28.3417 27.2501 28.4509 27.2294 28.5618C26.8297 28.5686 26.43 28.5822 26.0304 28.5822C24.3455 28.5857 22.6607 28.5857 20.9759 28.5822C20.5124 28.5822 20.1024 28.6965 19.9905 29.1981C19.8854 29.6621 20.242 29.8924 20.6055 29.9726C21.1051 30.0835 20.9965 30.4674 21.0568 30.7693C21.3101 32.0147 21.5237 32.2587 22.8123 32.3815C25.4033 32.6255 28.0046 32.4839 30.5991 32.4583C31.3812 32.4497 32.2029 32.303 32.7559 31.6087C33.4054 31.1225 33.7275 30.4605 33.8137 29.6724C33.8206 29.4335 33.8292 29.193 33.8361 28.9542C34.4528 28.8245 34.6526 29.0923 34.5975 29.6826C34.47 31.0457 33.6914 31.9857 32.6198 32.7466C32.2115 32.8575 31.8067 33.0451 31.3932 33.0673C28.0563 33.2396 24.7176 33.4017 21.3239 32.9087C21.4307 33.8589 21.5426 34.8586 21.6546 35.8583C19.4805 35.7918 17.303 35.959 15.1306 35.7679H15.1289ZM21.2033 24.2183C22.3437 24.2183 23.4859 24.1995 24.6246 24.2268C25.1483 24.2388 25.3275 24.0409 25.3189 23.5325C25.2965 22.1779 25.2999 20.8217 25.3172 19.4654C25.3223 18.9604 25.1208 18.7591 24.6074 18.7608C22.3541 18.7745 20.1007 18.7779 17.8491 18.7574C17.2875 18.7523 17.0721 18.9928 17.0739 19.5115C17.0756 20.8097 17.0859 22.1097 17.0687 23.408C17.0601 24.0272 17.3288 24.249 17.9525 24.2268C19.0343 24.1893 20.1197 24.2166 21.2033 24.2166V24.2183ZM18.5882 26.9308C18.5296 26.5828 18.3177 26.2928 17.887 26.2945C17.427 26.2945 17.1256 26.5794 17.1393 27.0315C17.1531 27.4819 17.4667 27.7565 17.9283 27.7275C18.3487 27.7002 18.5692 27.4153 18.5882 26.9308Z"
          fill="#595A5D"/>
      <path
          d="M21.6511 35.8569C21.5391 34.8571 21.4271 33.8574 21.3203 32.9072C24.7124 33.4002 28.0528 33.2382 31.3897 33.0658C31.8031 33.0437 32.208 32.856 32.6163 32.7451C32.5767 33.2262 32.537 33.7056 32.4991 34.1867C32.2735 34.2003 32.0478 34.2208 31.8221 34.2242C30.2251 34.2464 29.6497 34.8247 29.6411 36.4369C29.6411 36.7713 29.6738 37.1056 29.6928 37.44C29.3827 38.7093 29.0795 39.9802 28.7591 41.2461C28.6523 41.6692 28.8711 41.9336 29.1484 42.1878C27.2362 42.198 25.3222 42.2032 23.41 42.2202C22.6778 42.227 22.3161 41.8841 22.242 41.1642C22.0594 39.3934 21.8492 37.626 21.6494 35.8569H21.6511Z"
          fill="#CFE1FC"/>
      <path
          d="M29.1997 41.3571L29.1996 41.3575C29.1732 41.4621 29.1883 41.5217 29.2153 41.5752C29.2386 41.6213 29.2758 41.6717 29.3335 41.7332C29.8445 41.7355 30.3555 41.738 30.8664 41.7405C32.5818 41.7488 34.2964 41.7571 36.0112 41.7596C36.0113 41.7596 36.0114 41.7596 36.0115 41.7596L29.1997 41.3571ZM29.1997 41.3571C29.4567 40.335 29.7048 39.3115 29.9524 38.29M29.1997 41.3571L29.9524 38.29M29.9524 38.29C29.9843 38.1585 30.0162 38.027 30.0481 37.8956M29.9524 38.29L30.0481 37.8956M30.0481 37.8956C31.9692 37.8972 33.8899 37.8984 35.8106 37.8996L35.8106 37.8996C37.7424 37.9008 39.674 37.902 41.6061 37.9036C41.8485 38.6502 42.0062 39.4098 42.1696 40.1966C42.247 40.5689 42.3256 40.9474 42.415 41.3334M30.0481 37.8956L42.415 41.3334M42.415 41.3334C42.415 41.3334 42.415 41.3334 42.415 41.3333L42.8586 41.2307L42.415 41.3334Z"
          fill="#F6B906" stroke="#595F8A" stroke-width="0.910777"/>
      <path
          d="M41.9324 37.4484C37.8512 37.445 33.7701 37.4433 29.6906 37.4399C29.6734 37.1055 29.639 36.7711 29.6407 36.435C29.6476 34.8229 30.2247 34.2462 31.8217 34.2224C32.0473 34.2189 32.273 34.1985 32.4987 34.1848C33.2705 34.3128 33.8149 34.2804 33.7718 33.2158C33.7442 32.5266 34.4454 32.53 34.9881 32.5181C35.5428 32.5215 36.0992 32.5249 36.6539 32.53C37.455 32.5198 38.0115 32.7586 37.884 33.7054C37.8288 34.1081 38.0097 34.2565 38.4215 34.236C38.9917 34.207 39.5654 34.2189 40.1356 34.2326C41.307 34.2599 41.9427 34.8672 41.9772 36.0085C41.9927 36.4879 41.9496 36.9673 41.9324 37.4467V37.4484Z"
          fill="#8B3F0C"/>
      <path
          d="M36.6537 32.5318C36.099 32.5284 35.5425 32.525 34.9878 32.5199C34.9878 28.3606 34.9878 24.1997 34.9844 20.0388C34.9844 19.3905 35.0326 18.8071 35.9284 18.8975C36.7571 18.9299 36.652 19.544 36.652 20.0661C36.6572 24.2202 36.6554 28.3743 36.6537 32.5301V32.5318Z"
          fill="#595A5D"/>
      <path
          d="M32.749 31.607C32.1977 32.3013 31.3742 32.4497 30.5921 32.4566C27.9959 32.4821 25.3963 32.6254 22.8054 32.3798C21.5167 32.2587 21.3031 32.0147 21.0499 30.7676C20.9896 30.4674 21.0981 30.0818 20.5985 29.9709C20.235 29.8907 19.8784 29.6604 19.9835 29.1964C20.0972 28.6948 20.5055 28.5805 20.9689 28.5805C22.6538 28.5822 24.3386 28.5805 26.0234 28.5805C26.4231 28.5805 26.8228 28.5669 27.2224 28.5601C29.0089 28.5703 30.7937 28.5822 32.5784 28.5874C33.0332 28.5874 33.4966 28.5464 33.8274 28.9524C33.8188 29.1913 33.8119 29.4318 33.805 29.6707C33.0935 29.976 32.7214 30.4827 32.8024 31.276C32.8127 31.3835 32.7662 31.4961 32.7472 31.607H32.749Z"
          fill="#CFE1FC"/>
      <path
          d="M36.4645 9.66862C36.4524 7.62483 35.3016 6.68142 33.4531 6.50741C33.603 6.10138 33.8442 6.13038 34.0199 6.09797C35.3878 5.85401 36.2267 5.11361 36.4024 3.70445C36.4231 3.53215 36.4369 3.2609 36.7143 3.28137C36.9244 3.29672 36.933 3.53215 36.9537 3.68228C37.1587 5.11361 38.0063 5.87789 39.4241 6.08944C39.6016 6.11503 39.8738 6.11162 39.8651 6.38287C39.8617 6.46817 39.6705 6.60635 39.555 6.6183C37.8892 6.77866 37.107 7.78179 36.871 9.32742C36.8607 9.39566 36.7797 9.45196 36.7315 9.51508C36.6419 9.56626 36.5523 9.61744 36.461 9.66862H36.4645Z"
          fill="#F6B906"/>
      <path
          d="M10.8883 26.2861C11.1295 27.9955 12.0632 28.8673 13.879 29.0208C13.1296 29.5941 12.3061 29.4951 11.7721 30.041C11.2449 30.5818 11.2656 31.3291 10.797 32.1974C10.5731 30.3925 9.74098 29.3842 7.96484 29.1966C9.60489 28.8417 10.6316 28.0075 10.8883 26.2878V26.2861Z"
          fill="#F6B906"/>
      <path
          d="M26.8327 10.5996C27.1049 11.99 27.8146 12.7935 29.3065 12.9846C27.856 13.22 27.0738 13.9792 26.843 15.4276C26.6311 13.998 25.8679 13.2149 24.3984 13.0051C25.8197 12.7611 26.6604 12.0582 26.8327 10.5996Z"
          fill="#F6B906"/>
      <path
          d="M21.2011 24.22C20.1175 24.22 19.0322 24.1927 17.9503 24.2302C17.3284 24.2524 17.0596 24.0324 17.0665 23.4114C17.0838 22.1131 17.0734 20.8131 17.0717 19.5149C17.0717 18.998 17.2853 18.7557 17.8469 18.7608C20.1003 18.7813 22.3536 18.7779 24.6052 18.7642C25.1186 18.7608 25.3202 18.9638 25.315 19.4688C25.2995 20.8234 25.296 22.1796 25.3167 23.5359C25.3253 24.0443 25.1462 24.2422 24.6224 24.2302C23.482 24.203 22.3415 24.2217 21.2011 24.2217V24.22Z"
          fill="#F6B906"/>
      <path
          d="M18.586 26.9308C18.567 27.4153 18.3483 27.7002 17.9262 27.7275C17.4662 27.7582 17.1527 27.4835 17.1372 27.0314C17.1234 26.5776 17.4249 26.2944 17.8848 26.2944C18.3155 26.2944 18.5274 26.5827 18.586 26.9308Z"
          fill="#FAA71A"/>
      <path
          d="M32.7539 31.6072C32.7746 31.4963 32.8211 31.3837 32.809 31.2762C32.7263 30.483 33.1002 29.9746 33.8117 29.6709C33.7255 30.4591 33.4034 31.121 32.7539 31.6072Z"
          fill="#EFF0F0"/>
    </g>
    <defs>
      <clipPath id="clip0_4317_11389">
        <rect width="41.4404" height="41.4404" fill="white" transform="translate(1.45312 0.779785)"/>
      </clipPath>
    </defs>
  </svg>


</template>
